/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Button, Modal, Form } from 'react-bootstrap';
import { FaFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { downloadExcel } from 'react-export-table-to-excel';
import _orders from '../../services/order.service';
import { getRoleName } from '../../permission-utils/scopes';
import moment from 'moment';
import Span from '../Span/Span';

function ExportToExcelButton({ data, dataName, button, overlayPlacement = 'right', dataFetcherFunction }) {
	const [show, setShow] = useState(false);
	const [exportName, setExportName] = useState('');
	const [selectedFields, setSelectedFields] = useState([]);
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const todayDate = moment().format('DD-MM-YYYY');

	const columnMap = {
		categories: {
			name: { label: 'Nombre', link: (item) => `Category/${item._id}` },
		},
		users: {
			name: { label: 'Nombre', link: false },
			email: { label: 'E-mail', link: false },
			role: { label: 'Rol', link: false },
		},
		orders: { 
			internalId: { label: 'ID', link: (item) => `/order/${item._id}` },
			'shippingAddress.address': { label: 'Dirección', link: false },
			'shippingAddress.telephone': { label: 'Teléfono', link: false },
			'shippingAddress.timeForDelivery': {
				label: 'Horario de Entrega',
				link: false,
			},
			paymentMethod: { label: 'Método de pago', link: false },
			status: { label: 'Estado', link: false },
			seller: { label: 'Vendedor', link: false },
			orderItems: { label: 'Detalles', link: false },
			totalPrice: { label: 'Total', link: false },
			createdAt: { label: 'Fecha', link: false },
		},
		products: {
			name: { label: 'Nombre' },
			brand: { label: 'Marca', link: false },
			price: { label: 'Precio', link: false, isCurrency: true },
			'category.name': { label: 'Categoría', link: false },
			countInStock: { label: 'Stock', link: false },
			active: { label: 'Está Activo?', link: false },
		},
	};

	const columns = Object.keys(columnMap[dataName] || {});
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columns.length / columnsPerRow) },
		(_, rowIndex) =>
			columns.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const handleDownloadOrderExcel = async () => {
		const dateFromIsValid = !dateFrom || moment(dateFrom).isValid();
		const dateToIsValid = !dateTo || moment(dateTo).isValid();
		const bothDatesAreEntered = dateFrom && dateTo;
		const dateToIsGreaterThanFrom = moment(dateTo).isAfter(dateFrom);
		if (exportName && dateFromIsValid && dateToIsValid) {
			if (!bothDatesAreEntered || dateToIsGreaterThanFrom) {
				try {
					const res = await _orders.getExcelSheet(selectedFields, dateFrom, dateTo, exportName);
					const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', exportName);
					document.body.appendChild(link);
					link.click();
					handleClose();
				} catch (ex) {
					console.error(ex);
					toast.error('No se pudo exportar la lista,');
				}
			} else {
				toast.error('La fecha de inicio debe ser menor a la fecha de fin');
			}
		} else if (!exportName) {
			toast.error("Error. Ingrese un nombre para el archivo");
		} else {
			toast.error("Error. Revise las fechas ingresadas");
		}
	};

	//para variables inside variables
	function getNestedValue(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		for (const key of keys) {
			if (nestedValue && nestedValue[key] !== 'undefined') {
				nestedValue = nestedValue[key];
			} else {
				return null;
			}
		}
		return nestedValue;
	}

	//to show date in format (DD-MM-YYYY)
	const formatValue = (dataName, value, field = null) => {
		if (dataName === 'orders' && field === 'status') {
			let variant = '';
			let caption = value;
			if (caption === 'Cancelled') {
				variant = 'danger';
			} else if (
				caption === 'Pending Payment' ||
				caption === 'Awaiting Confirmation'
			) {
				variant = 'warning';
			} else {
				variant = 'success';
			}
			return <Span variant={variant}>{caption}</Span>;
		}
		if (dataName === 'users' && getRoleName(value)) {
			return getRoleName(value);
		}
		if (value === true) {
			return 'Si';
		} else if (value === false) {
			return 'No';
		}
		const isValidDateFormat = moment(
			value,
			'YYYY-MM-DDTHH:mm:ss.SSSZ',
			true,
		).isValid();
		if (isValidDateFormat) {
			return moment(value).format('DD-MM-YYYY');
		} else {
			return value;
		}
	};


	const handleDownloadExcel = async () => {
		try {
			const selectedColumnMap = columnMap[dataName];
			const header = selectedFields.map((field) => selectedColumnMap[field].label);
			const dataToMap = dataFetcherFunction ? (await dataFetcherFunction()).data : data;
			const body = dataToMap.map((item) =>
				selectedFields.map((field) =>
					formatValue(dataName, getNestedValue(item, field)),
				),
			);

			downloadExcel({
				fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
				sheet: 'react-export-table-to-excel',
				tablePayload: {
					header,
					body,
				},
			});
			setSelectedFields([]);
			handleClose();
		} catch (ex) {
			console.error(ex);
			toast.error("No se pudo exportar la tabla");
		}

	};
	


	return (
		<Row className="m-1">
			<Col className="d-flex">
				<OverlayTrigger
					placement={overlayPlacement}
					overlay={<Tooltip id="tooltip-top">Exportar a Excel</Tooltip>}
				>
					{button ? (
						React.cloneElement(button, { onClick: handleShow })
					) : (
						<Button size={'sm'} className="mb-1" style={{ margin: '0' }} onClick={handleShow}>
							<FaFileExcel />
						</Button>
					)}

				</OverlayTrigger>
			</Col>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Exportar datos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className="mb-3 align-items-center">
						<Form.Label column md={6}>
							Nombre del archivo:
						</Form.Label>
						<Col>
							<Form.Control
								type="text"
								placeholder="Ingrese el nombre"
								value={exportName}
								onChange={(e) => setExportName(e.target.value)}
							/>
						</Col>
					</Form.Group>
					{dataName === "orders" &&
						<>
							< Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column md={6}>
									Fecha desde:
								</Form.Label>
								<Col>
									<Form.Control
										type="date"
										value={dateFrom}
										onChange={(e) => setDateFrom(e.target.value)}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="mb-3 align-items-center">
								<Form.Label column md={6}>
									Fecha hasta:
								</Form.Label>
								<Col>
									<Form.Control
										type="date"
										value={dateTo}
										onChange={(e) => setDateTo(e.target.value)}
									/>
								</Col>
							</Form.Group>
						</>}
					<>
						<p>Marque los campos que desea incluir</p>
						{rows.map((row, rowIndex) => (
							<Row key={rowIndex}>
								{row.map((column) => (
									<Col key={column} md={6}>
										<Form.Group
											as={Row}
											className="mb-3 align-items-center"
											controlId={`fieldsToInclude_${column}`}
										>
											<Form.Check
												className="col-8"
												type="checkbox"
												value={column}
												label={columnMap[dataName][column].label}
												defaultChecked={column === 'customId'}
												disabled={column === 'customId'}
												onChange={(e) => {
													const selected = e.target.checked;
													setSelectedFields((prevSelectedFields) =>
														selected
															? [...prevSelectedFields, column]
															: prevSelectedFields.filter((field) => field !== column),
													);
												}}
											/>
										</Form.Group>
									</Col>
								))}
							</Row>
						))}
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={dataName === "orders" ? handleDownloadOrderExcel: handleDownloadExcel}>
						Exportar XLS
					</Button>
				</Modal.Footer>
			</Modal>
		</Row>
	);
}

export default ExportToExcelButton;
